<template>
    <div class="CEESAR-Application">
        <slot />

        <CEESAR-Toast/>
        <CEESAR-DialogHost />

        <div class="CEESAR-ApplicationDialog">
            <CEESAR-Dialog  id="ConfirmationDialog"
                            title="Confirm"
                            :buttons="confirmButtons">
                {{confirmMessage}}
            </CEESAR-Dialog>
        </div>

    </div>
</template>

<script>
export default {
    name: "CEESAR-Application",
    data(){
        return{
            confirmMessage: "",
            confirmButtons: undefined
        };
    },
    created(){
        this.$ceesar.design.registerConfirmEvent("Show", this.showConfirm);
    },
    beforeDestroy(){
        this.$ceesar.design.unregisterConfirmEvent("Show");
    },
    methods:{
        showConfirm(pMessage, pButtons){
            this.confirmMessage = pMessage;
            this.confirmButtons = pButtons;

            this.$ceesar.design.showDialog("ConfirmationDialog");
        }
    }
}
</script>

<style>
</style>